<template>
  <section class="section">
    <div class="container">
      <div class="page-header">
        <h3>Order: OR1082211</h3>
      </div>

      <hr class="hline" />

      <div class="columns">
        <div class="column">
          <div class="box">
            Invoice
          </div>
        </div>
        <div class="column is-4">
          <div class="box">
            <ul class="actions">
              <li>
                <div class="icon">
                  <i class="fal fa-file-pdf"></i>
                </div>
                <span>Download</span>
              </li>
              <li>
                <div class="icon">
                  <i class="fal fa-print"></i>
                </div>
                <span>Print</span>
              </li>
              <li>
                <div class="icon">
                  <i class="fal fa-envelope-open-text"></i>
                </div>
                <span>Email</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page-header {
  justify-content: space-between;
  align-items: flex-end;
}

.hline {
  width: 100%;
  height: 1px;
}

.actions {
  li {
    padding: 10px;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    span {
      transition: all ease-in-out 0.3s;
    }
    .icon {
      transition: ease 0.3s;
      margin-right: 10px;
      background-color: rgba(var(--primary-color), 0.1);
      width: 30px;
      height: 30px;
      i {
        color: var(--primary-color);
      }
    }
    &:hover {
      span {
        color: var(--primary-color);
      }
      .icon {
        background-color: var(--primary-color);
        i {
          font-weight: 700;
          color: #fff;
        }
      }
    }
  }
}
</style>
